import request from "@/utils/request.js";

/**
 * @description: 账号登录
 * @param {*} query
 */
export function login(params) {
  return request({
    url: "/api/Auth/Login",
    method: "post",
    data: params,
  });
}

/**
 * @description: 账号绑定
 * @param {*} query
 */
export function wechatBindAuth(params) {
  return request({
    url: "/api/Auth/WechatBindAuth",
    method: "post",
    data: params,
  });
}

/**
 * @description: 根据code判断是否授权
 * @param {*} query
 */
export function wechatAuth(params) {
  return request({
    url: "/api/Auth/WechatAuth" + `/${params}`,
    method: "get",
  });
}
